import React from 'react'
import { graphql } from 'gatsby'
import { Router } from '@reach/router'
import { Helmet } from 'react-helmet'

import AvatarCard from '../../components/AvatarCard/AvatarCard'
import Header from '../../components/Header/Header'
import Layout from '../../components/Layout/Layout'
import Legend from '../../components/Mississippi/Legend/Legend'
import Mississippi from '../../components/Mississippi/Mississippi'

import '../../styles/education.css'

const DistrictInfo = (props) => {
  const county = props.data.find(item => item.name === props.county)
  let representatives = []
  for (let count = 0; count < county.districts.length; count++) {
    let representative = props.reps.find(rep => rep.district === county.districts[count].district)
    representatives = [...representatives, representative]
  }

  return county ? (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
      <h3>{county.name} County</h3>
      <p>{county.name} County is in {county.districts.length > 1 ? 'Districts' : 'District'} {county.districts.map((district, index) => (index ? ' and ' : '') + district.district)}.</p>
      <p>The United States {representatives.length > 1 ? 'Representatives' : 'Representative'} for {county.name} County {representatives.length > 1 ? 'are' : 'is'}:</p>
      <div className="flex-row justify-space-around">
        {representatives.map(representative => <AvatarCard member={representative} />)}
      </div>
    </div>
  ) : null
}

const USRepresentation = ({ data }) => {
  return (
    <Layout title={data.markdownRemark.frontmatter.heading}>
      <Helmet>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}
        </script>
      </Helmet>
      <Header background={data.markdownRemark.frontmatter.headerImage.photo} alt={data.markdownRemark.frontmatter.headerImage.altText}>{data.markdownRemark.frontmatter.heading}</Header>
      <div className="main">
        <h2>US Senators</h2>
        <div className="flex-row justify-space-around">
          {data.markdownRemark.frontmatter.senators.map(senator => <AvatarCard member={senator} senator />)}
        </div>
        <h2>US Representatives</h2>
        <div className="flex-row justify-space-around">
          {data.markdownRemark.frontmatter.representatives.map(representative => <AvatarCard member={representative} representative />)}
        </div>
        <h3>Which district am I in?</h3>
        <p className="center-text">{data.markdownRemark.frontmatter.instructions}</p>
        <div className="flex-row flex-mobile">
          <div className="mississippi-districts">
            <h4 style={{ marginTop: 0 }}>Mississippi Congressional Districts</h4>
            <Legend districtNo="1" districtColor="rgba(146, 121, 183, 0.5)" />
            <Legend districtNo="2" districtColor="rgba(146, 121, 183, 0.8)" />
            <Legend districtNo="3" districtColor="rgba(146, 121, 183, 1)" />
            <Legend districtNo="4" districtColor="rgba(80, 52, 110, 0.9)" />
            <small><strong>Note:</strong> Clarke, Madison, and Oktibbeha counties are in more than one district.</small>
          </div>
          <Mississippi district />
        </div>
        <Router basepath="/education/us-representation/" className="equal">
          <DistrictInfo path="/:county" data={data.markdownRemark.frontmatter.counties} reps={data.markdownRemark.frontmatter.representatives} />
        </Router>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query USRepresentationQuery {
  markdownRemark(frontmatter: {title: {eq: "US Representation"}}) {
    frontmatter {
      heading
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
      senators {
        name
        photo {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              aspectRatio: 1
              transformOptions: {fit: INSIDE, cropFocus: ATTENTION, trim: 10}
            )
          }
        }
        party
        dateInOffice
      }
      representatives {
        name
        photo {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              aspectRatio: 1
              transformOptions: {fit: INSIDE, cropFocus: ATTENTION, trim: 10}
            )
          }
        }
        district
        party
        dateInOffice
      }
      instructions
      counties {
        name
        districts {
          district
        }
      }
    }
  }
}
`

export default USRepresentation