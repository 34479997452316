import React from 'react'

import * as styles from './Legend.module.css'

const Legend = (props) => (
  <div className={styles.legend}>
    <div className={styles.color_block} style={{
      backgroundColor: props.districtColor,
    }}>
    </div> District {props.districtNo}
  </div>
)

export default Legend